




















import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Inspection_inspection_checks, InspectionStatus } from '@/types/intrador';
import InspectionAnomalyMixin from '@/components/inspection/anomalies/InspectionAnomalyMixin';
import { Checks } from '@/components/inspection/InspectionAnomalies.vue';

@Component
export default class AnomaliesAmount extends Mixins(InspectionAnomalyMixin) {
  @Prop(String) private status?: InspectionStatus;
  @Prop(Number) private anomaliesAmount!: number;

  private statusExpired = InspectionStatus.EXPIRED;

  /**
   * Convert list to dictionary based on namespace
   *
   * @private
   */
  private get checks(): Checks | null {
    if (this.inspection) {
      // Should keep last checks (if there is ever any overlap)
      return (this.inspection.checks || [])
      .sort((a, b) => (parseInt(b.id, 10) - parseInt(a.id, 10)))
      .reduce((d: Checks, c: Inspection_inspection_checks) => {
        if (c.namespace in d) {
          return d;
        }

        return {
          ...d,
          [c.namespace]: {
            ...c,
          },
        };
      }, {});
    }
    return null;
  }

  private get isDoneChecking() {
    if (this.checks) {
      return this.checks.SerialNumber && this.checks.PictureInPicture &&
        this.checks.Distance && this.checks.Inconsistency;
    }
  }

  private get anomaliesAmountTooltip() {
    if (!this.isDoneChecking) {
      // tslint:disable-next-line:max-line-length
      return this.$it('inspection.anomalies.amount.anomalies-found.checks-not-done', {anomaliesAmount: this.anomaliesAmount}, 'Not all standard checks have been performed, {anomaliesAmount} anomalies have been found');
    } else {
      return this.$it('audit.review.anomaly-amount', 'Anomaly amount');
    }
  }

  private get anomaliesStatusDetail() {
    if (!this.isDoneChecking && this.anomaliesAmount < 1) {
      // tslint:disable-next-line:max-line-length
      return this.$it('inspection.anomalies.amount.anomalies-found.checks-not-done', {anomaliesAmount: this.anomaliesAmount}, 'Not all standard checks have been performed, {anomaliesAmount} anomalies have been found');
    } else {
      return this.$it('anomalies.amount.status-expired', 'Status expired');
    }
  }
}
