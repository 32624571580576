import {AuditStatus} from '@/types/intrador';
import i18n from '@/store/i18n';

export function getAuditStatusTranslation(key: AuditStatus): string|null {
  switch (key) {
    case AuditStatus.CLOSED:
      return i18n.it('audit.status.closed.title', 'Closed') as string;
    case AuditStatus.IN_PROGRESS:
      return i18n.it('audit.status.in-progress.title', 'In progress') as string;
    case AuditStatus.IN_REVIEW:
      return i18n.it('audit.status.in-review.title', 'In review') as string;
    case AuditStatus.POSTPONED:
      return i18n.it('audit.status.postponed.title', 'Postponed') as string;
  }

  return null;
}

export default {
  getTranslation: getAuditStatusTranslation,
};
