import { Component, Vue } from 'vue-property-decorator';
import {Account, Account_account} from '@/types/intrador';
import { Filter, FilterType } from '@/layouts/back-office/elements/filters/FilterMixin';
// @ts-ignore
import { FilterQuerySelectOptions } from '@/layouts/back-office/elements/filters/QuerySelectFilter.vue';

@Component
export default class AccountManagerFilterMixin extends Vue {

  protected get defaultAccountManager(): Account_account | null {
    const data: Account | null = this.$apollo.getClient().cache.readQuery({
      query: require('@/graphql/Account.gql'),
    });

    const account = (data) ? data.account : null;
    return (account && account.isAccountManager) ? account : null;
  }

  protected get hasDefaultAccountManager() {
    const accountManager = this.defaultAccountManager;

    return accountManager &&
      accountManager.entity?.config?.defaultAccountManagerFilter && accountManager.isAccountManager;
  }

  protected get accountManagerFilter(): Filter {
    const accountManager = this.defaultAccountManager;

    return {
      key: 'accountManagerIds',
      title: this.$it('filter.account-manager.title', 'Account Manager') as string,
      type: FilterType.QuerySelect,
      default: (accountManager && this.hasDefaultAccountManager) ? [accountManager.id] : null,
      options: {
        query: require('@/graphql/PaginateAccountManagers.gql'),
        dataKey: 'paginateAccountManagers',
        selectedLabels: (accountManager && this.hasDefaultAccountManager) ? [accountManager.name] : null,
      } as FilterQuerySelectOptions,
    };
  }
}
