












import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionOld_inspection_asset_meta } from '@/types/intrador';

interface MetaDict {
  [key: string]: string|null;
}

export interface MetaListItem {
  value: string|null;
  tooltip: string|null;
}

@Component({})
export default class MetaList extends Vue {
  @Prop(Array) private meta!: InspectionOld_inspection_asset_meta[];
  @Prop(Array) private keys!: string[];
  @Prop(Array) private tooltips!: string[];
  @Prop({type: Array, default: () => []}) private prepend!: MetaListItem[];
  @Prop({ type: String, default: 'down' }) private balloon!: string;

  get metaDict(): MetaDict {
    const dict: MetaDict = {};
    this.meta.map((m) => (dict[m.key] = m.value));
    return dict;
  }

  get values(): MetaListItem[] {
    const data: MetaListItem[] = [];

    this.prepend.filter((item) => item.value)
      .forEach((item) => data.push(item));

    this.keys.map((key) => this.metaDict[key])
      .forEach((item, i) => {
        if (typeof item === 'string') {
          data.push({
            value: item,
            tooltip: (this.tooltips && this.tooltips.length > i) ? this.tooltips[i] : null,
          });
        }
      });

    return data;
  }
}
