
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuditRating, PaginateAudits_paginateAudits_data_branch_endedAudits } from '@/types/intrador';

@Component
export default class LastAuditRating extends Vue {
  @Prop({ type: Object }) private audit?: PaginateAudits_paginateAudits_data_branch_endedAudits;

  private AuditRating = AuditRating;
}
