import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection, Inspection_inspection_checks } from '@/types/intrador';

@Component
export default class InspectionAnomalyMixin extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;
  @Prop(Object) protected check?: Inspection_inspection_checks;

  /**
   * The message of the check
   *
   * @private
   */
  protected get message(): string | undefined {
    return this.check && this.check.message;
  }

  /**
   * The level of the check
   *
   * @private
   */
  protected get level(): string | undefined {
    return this.check && this.check.level;
  }

  /**
   * The attributes of the check
   *
   * @private
   */
  protected get attributes(): { [key: string]: any } {
    if (this.check) {
      return JSON.parse(this.check.attributes || '{}');
    }

    return {};
  }
}
