






import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuditStatus } from '@/types/intrador';
import {getAuditStatusTranslation} from '@/plugins/audit/AuditStatus';

@Component({})
export default class AuditStatusLabel extends Vue {
  @Prop(String) protected status!: AuditStatus;

  get label(): string | null {
    return getAuditStatusTranslation(this.status);
  }
}
