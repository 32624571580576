











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Score extends Vue {
  @Prop(Number) private score!: number;
  @Prop({ type: Boolean, default: false }) private inline!: boolean;
  @Prop({ type: String, default: 'down' }) private balloonPos!: string;
}
