import {AuditRating} from '@/types/intrador';
import i18n from '@/store/i18n';
import {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';

export const ratingKeys = Object.keys(AuditRating);

export function getAuditRatingTranslation(key: AuditRating) {
  switch (key) {
    case AuditRating.NOT_CONTENT:
      return i18n.it('audit.rating.not-content.title', 'Unsatisfactory') as string;
    case AuditRating.HALF_CONTENT:
      return i18n.it('audit.rating.half-content.title', 'Satisfactory with reservations') as string;
    case AuditRating.CONTENT:
      return i18n.it('audit.rating.content.title', 'Satisfactory') as string;
  }
}

export function getRatingFilterOptions(keys: string[] = []): FilterSelectOption[] {
  const ratingOptions: FilterSelectOption[] = [];

  if (keys.length === 0) {
    keys = ratingKeys;
  }

  keys.forEach((key: string) => {
    ratingOptions.push({
      key,
      label: getAuditRatingTranslation(key as AuditRating),
    });
  });

  return ratingOptions;
}


export default {
  keys: ratingKeys,
  getTranslation: getAuditRatingTranslation,
  getFilterOptions: getRatingFilterOptions,
};
